import '../sass/core.scss';
import '../sass/framework/hd.scss';

import "./global";
import {lazyCSS} from "./class/lazyCSS";
import {lazyImg} from "./class/lazyImg";

const mod = {};

// ======== load core =========
mod.core = () => {
  mod.css = new lazyCSS()
  mod.lazy = new lazyImg('[data-url]')
  addCSS('https://use.typekit.net/qfr5lnk.css')
};

!is_observer() ? addJS('js/polyfill.js', {
  cb: mod.core
}) : document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core()
